<template>
    <div>
        <a-modal title="更换预约时间" :width="540" :visible="visible" @cancel="handleCancel">
            <template slot="footer">
                <a-button key="back" @click="handleCancel">
                    取消
                </a-button>
                <a-button key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
                    确认更换
                </a-button>
            </template>
            <div>
                <a-spin :spinning="loading">
                    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
                        <a-form-model-item label="预约时间" required>
                            <a-date-picker valueFormat="YYYY-MM-DD" v-model="form.day" />
                            <div class="mt10">
                                <select-time-range v-model="form.appoint_time"></select-time-range>
                            </div>
                        </a-form-model-item>
                    </a-form-model>
                </a-spin>
            </div>
        </a-modal>
    </div>
</template>

<script>
    import selectTimeRange from './addAppointment/selectTimeRange.vue';
    export default {
        components: {
            selectTimeRange,
        },
        props: {
            visible: {
                type: Boolean,
                default: false,
            },
            order:{
                type:Object,
                default:function(){
                    return new Object
                },
            },
        },

        data() {
            return {
                loading: false,
                confirmLoading: false,
                labelCol: {
                    span: 6
                },
                wrapperCol: {
                    span: 18
                },
                form: {
                    day: '',
                    appoint_time: [],
                }
            }
        },
        methods: {

            /**
             * 取消弹窗
             */
            handleCancel() {
                this.$emit("cancel");
            },

            /**
             * 提交数据
             */
            onSubmit() {
                if (this.confirmLoading == true) return;
                this.confirmLoading = true;
                this.$http.api('admin/changeselectTime', {
                    appointment_id: this.order.appointment_id,
                    day: this.form.day,
                    appoint_time: this.form.appoint_time,
                }).then(res => {
                    this.$message.success('更换预约时间成功');
                    this.$emit("ok");
                    this.confirmLoading = false;
                }).catch(res => {

                    this.confirmLoading = false;
                })
            },
        }
    }
</script>

<style>

</style>
