<template>
	<div v-if="order!=null">
		<a-spin :spinning="loading">
      <div class="flex space alcenter">
          <div class="ft20 cl-black cl-main ftw500">工单详情</div>
          <a-button @click="$router.go(-1)">返回</a-button>
      </div>

			<div class="mt20">
				<div class="bg-w pd40" style="min-height: 800px;">
					<div class="">
						<div class="flex alcenter">
							<div class="">
								<div class="flex alcenter">
									<div class="flex alcenter">
										<div class="ft14 ftw400 cl-info">订单编号：</div>
										<div class="ft14 ftw600 cl-main">{{order.appointment_id}}</div>
									</div>

									<div class="flex alcenter ml40">
										<div class="ft14 ftw400 cl-info">下单时间：</div>
										<div class="ft14 ftw600 cl-main">{{order.add_time_format}}</div>
									</div>

									<div class="flex alcenter ml40">
										<div class="ft14 ftw400 cl-info text-over4">预约时间：</div>
										<div class="flex alcenter">
											<div class="ft14 ftw600 cl-main text-over4">{{order.day}}</div>
											<div class="ft14 ftw600 cl-theme text-over4">{{order.start_time}}-{{order.end_time}}</div>
										</div>
									</div>
                  <div v-if="order.status==1 || order.status==3"  class="flex alcenter ml40">
                      <a-button type="primary" @click="changetimesAct" >
                          修改预约时间
                      </a-button>
                  </div>
								</div>
							</div>
						</div>
					</div>

					<div class="mt30">
						<div class="mall-goods_order-detail-info">
							<div class="flex ">
								<div class="pl40" style="width:28%;border-right: 1px solid #EBEDF5;">
									<div class="ft14 ftw500 cl-main">订单状态</div>
									<div class="mt16 cl-theme mt16" v-if="order.status==1">待确认</div>
									<div class="mt16 cl-theme mt16" v-if="order.status==3">待服务</div>
									<div class="mt16 cl-theme mt16" v-if="order.status==4">服务中</div>
									<div class="mt16 cl-theme mt16" v-if="order.status==8">已完成</div>
									<div class="mt16 cl-theme mt16" v-if="order.status==-1">已取消</div>
                  <div class="flex alcenter mt10" v-if="order.serviceman_name != ''">
                    <div class="ft14 cl-info text-over4">服务人员：</div>
                    <div class="ft14 cl-main text-over4" >
                      {{order.serviceman_name}} {{order.serviceman_mobile}}
                    </div>
                  </div>
									<div class="mt16 flex alcenter" v-if="order.status==1">
										<div class="button-deliver "  @click="sureAppointAct()">确认预约</div>
										<div class="button-cancel ml20"  @click="cancelAppointAct()">取消预约</div>
									</div>
									<div class="mt16 flex alcenter" v-if="order.status==3">
										<div class="button-deliver " @click="startServiceAct()">开始服务</div>
										<div class="button-cancel ml20"  @click="cancelAppointAct()">取消预约</div>
									</div>
									<div class="button-deliver mt16"  v-if="order.status==4" @click="completeAppointAct()">完成服务</div>
								</div>

								<div class="" style="width:28%;">
									<div class="flex" style="border-right: 1px solid #EBEDF5; ">
										<div class="ml40 text-over4">
											<div class="ft14 ftw500 cl-main text-over4">订单信息</div>
											<div class="mt16">
												<div class="flex">
													<div>
														<div class="flex alcenter ">
															<div class="ft14 cl-info text-over4">支付方式：</div>
															<div class="ft14 cl-main text-over4" >
																{{order.pay_type_mean}}
															</div>
														</div>
														<div class="flex alcenter mt10">
															<div class="ft14 cl-info text-over4">支付时间：</div>
															<div class="ft14 cl-main text-over4" >
																{{order.pay_time_format}}
															</div>
														</div>
														<div class="flex alcenter mt10">
															<div class="ft14 cl-info text-over4">实付金额：</div>
															<div class="flex alcenter" v-if="order.is_paid==1">
																<div class="ft14 cl-main text-over4" >¥{{order.need_pay}}</div>
																<div class="ml8 cl-notice">原价：</div>
																<div class=" cl-notice text-line">{{order.total_price}}</div>
																<div class="ml8 flex alcenter" v-if="order.added_price>0">
																	<div class=" cl-info">增值价格：</div>
																	<div class=" cl-red ">+{{order.added_price}}</div>
																</div>
															</div>
															
															<div class="flex alcenter" v-else>
																<div class="ft14 cl-main text-over4" >未支付</div>
																<div class="ml8 cl-notice">原价：</div>
																<div class=" cl-notice text-line">{{order.total_price}}</div>
															</div>
														</div>
														<div v-if="order.added_price_info">
															<span class="ft14 ftw400 cl-info">增值说明:</span>
															<span class="cl-notice ft14">{{order.added_price_info}}</span>
														</div>
                            <div class="flex alcenter mt10" v-if="order.is_error==1">
                              <div class="ft14 cl-info text-over4">异常工单：</div>
                              <div class="ft14 cl-main text-over4" >
                                {{order.error_type_dase}}
                              </div>
                            </div>
													
														
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="" style="width:44%;">
                  <div class="flex" style="border-right: 1px solid #EBEDF5; ">
                    <div class="ml40 text-over4">
                      <div class="ft14 ftw500 cl-main text-over4">客户信息</div>
                      <div class="mt16">
                        <div class="flex">
                          <div>
                            <div class="flex alcenter ">
                              <div class="ft14 cl-info text-over4">联系人：</div>
                              <div class="ft14 cl-main text-over4" >
                                {{order.contact_name}} {{order.contact_mobile}}
                              </div>
                            </div>
                            <div class="flex alcenter mt10">
                              <div class="ft14 cl-info text-over4">服务地址：</div>
                              <div class="ft14 cl-main text-over4" >
                                {{order.contact_address}}
                              </div>
                            </div>
                            <div class="flex alcenter mt10">
                              <div class="ft14 cl-info text-over4">长者信息：</div>
                              <div class="ft14 cl-main text-over4" >
                                {{oldmember.name}} {{oldmember.gender}} {{oldmember.age}}岁
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

								</div>
							</div>
						</div>
					</div>

          <a-divider orientation="left">服务项目</a-divider>
					<div class="mt5">
						<div class="flex wrap">
							<div class="mall-goods_order-detail-subcard-item" >
								<div class="flex  alcenter space">
									<div class="flex alcenter">
										<img v-if="order.project_cover_img != null" :src="order.project_cover_img" />
										<div class="ml10">
											<div class="ft14 ftw600 cl-main" style="width: 500px;">
												{{order.project_name}}
											</div>
											<div class="ft12 ftw400 cl-main mt5" >
												¥{{order.total_price}}
											</div>
											<div class="ft12 ftw400 cl-notice mt5">{{order.project_sku_name}}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="flex end mt10">
						<div class="flex" v-if="order.is_paid==1">
							<div class="ft12 ftw400 cl-info">
								<div class="flex end">合计</div>
								<div class="flex end mt8" v-if="order.added_price>0">增值价格</div>
								<div class="flex end mt8" v-if="order.coupon_money>0">优惠券抵扣</div>
								<div class="flex end mt8" v-if="order.integral_balance>0">积分抵扣</div>
								<div class="flex end mt8" v-if="order.pay_type=='offline' && order.youhui_balance > 0">优惠金额</div>
								<div class="flex end mt8 ft14 ftw500 cl-black">订单金额</div>
							</div>

							<div class="ml60 ft12 ftw400 cl-info">
								<div class="flex end">¥{{order.total_price}}</div>
								<div class="flex end mt8">+¥{{order.added_price}}</div>
								<div class="flex end mt8" v-if="order.coupon_money>0">-¥{{order.coupon_money}}</div>
								<div class="flex end mt8" v-if="order.integral_balance>0">-¥{{order.integral_balance}}
								</div>
								<div class="flex end mt8" v-if="order.pay_type=='offline' && order.youhui_balance > 0">-¥{{order.youhui_balance}}</div>
								<div class="flex end mt8 ft14 ftw500 cl-black">¥{{orderPrice}}</div>
							</div>
						</div>
					</div>

          <div>
            <a-divider orientation="left">服务记录</a-divider>
            <div class="mt5">
              <a-descriptions :column="1">
                <a-descriptions-item label="开始时间">{{order.services.start_forma}}</a-descriptions-item>
                <a-descriptions-item label="结束时间" style="width: 400px;"> {{order.services.end_forma}}</a-descriptions-item>
                <a-descriptions-item label="备注"> {{order.services.notes}}</a-descriptions-item>
                <a-descriptions-item label="服务凭证">
                  <img v-if="item != null" :src="item.url" class="member-detail-face mr10"  v-for="(item,index) in order.services.pictures"/>
                </a-descriptions-item>
              </a-descriptions>
            </div>
          </div>

          <a-divider orientation="left">工单日志</a-divider>
          <div class="mt10">
            <div class="wxb-table-gray">
              <a-table rowKey="id" :columns="columns" :data-source="logdatas" :loading="loading">
              </a-table>
            </div>
          </div>
					
					<div v-if="cancelAppointVisible">
						<cancel-appoint :appointment_id="order.appointment_id" :visible="cancelAppointVisible" @cancel="cancelCancelAppoint" @ok="okCancelAppoint"></cancel-appoint>
					</div>
					<div v-if="distributionServicemanVisible">
						<distribution-serviceman :appointment_id="order.appointment_id" :visible="distributionServicemanVisible" @cancel="cancelDistributionServiceman"
						@ok="okDistributionServiceman"></distribution-serviceman>
					</div>
					<div v-if="completeAppointVisible">
						<complete-appoint :order="order" :visible="completeAppointVisible" @cancel="cancelCompleteAppoint" @ok="okCompleteAppoint"></complete-appoint>
					</div>
                    <div v-if="changetimesVisible">
                        <changetimes-template :order="order" :visible="changetimesVisible" @cancel="cancelChangeTimes" @ok="okChangetimes"></changetimes-template>
                    </div>
				</div>
			</div>
		</a-spin>
	</div>

</template>

<script>
	import cancelAppoint from './components/order/modal/detail/modal/cancelAppoint.vue';
	import completeAppoint from './components/order/modal/completeAppoint.vue';
	import distributionServiceman from './components/order/modal/distributionServiceman.vue';
	import changetimesTemplate from './components/order/modal/changeTimes.vue';
	export default {
		components:{
			cancelAppoint,
			distributionServiceman,
			completeAppoint,
            changetimesTemplate,
		},
		data() {
			return {
				loading: false,
				cancelAppointVisible:false,
				distributionServicemanVisible:false,
				completeAppointVisible:false,
                changetimesVisible:false,
				appointment_id: 0,
				order: null,
				oldmember:null,
                logdatas:[],
                columns: [
                    {title: '序号',dataIndex: 'id',align: 'left',width:100},
                    {title: '时间',dataIndex: 'create_time',align: 'left',width:190},
                    {title: '操作员',dataIndex: 'operate',align: 'left',width:250},
                    {title: '动作',dataIndex: 'channel_text',align: 'left',width:100},
                    {title: '备注',dataIndex: 'content',align: 'left'},
                ],
			}
		},
		created() {
			if (!this.$route.query.id) {
				this.appointment_id = 0;
			} else {
				this.appointment_id = parseInt(this.$route.query.id);
			}
			this.loaddata();
		},
		computed:{
			orderPrice(){
				return parseFloat(this.order.need_pay)+parseFloat(this.order.added_price)
			}
		},
		methods: {
			loaddata() {
				if (this.loading == true) return;
				this.loading = true;
				this.$http.api('admin/getAppointmentDetail', {
					appointment_id: this.appointment_id
				}).then(res => {
					this.order = res.detail;
					this.oldmember=res.detail.oldmember;
					this.logdatas=res.loglist;

					this.loading = false;
				}).catch(res => {
					console.log(res);
					this.loading = false;
					this.$router.go(-1);
				})
			},
            changetimesAct(){
                this.changetimesVisible=true;
            },
            cancelChangeTimes(){
                this.changetimesVisible=false;
            },
            okChangetimes(){
                this.changetimesVisible=false;
                this.loaddata();
            },

            sureAppointAct(){
				if(this.order.serviceman_id!=0){
					this.$confirm({
						title:'确认这个预约吗？',
						okText:"确定",
						okType:"danger",
						cancelText:"取消",
						onOk : ()=>{
							return new Promise((resolve,reject)=>{
								this.$http.api('admin/sureAppointment',{
									appointment_id:this.order.appointment_id,
								}).then(res=>{
									this.$message.success('确认成功');
									this.loaddata();
								}).catch(res=>{
									console.log(res);
								}).finally(()=>{
									resolve();
								})
							})
						}
					})
				}else{
					this.distributionServicemanAct();
				}
			},
			
			distributionServicemanAct(){
				this.distributionServicemanVisible=true;
			},
			cancelDistributionServiceman(){
				this.distributionServicemanVisible=false;
			},
			okDistributionServiceman(){
				this.distributionServicemanVisible=false;
				this.loaddata();
			},
			
			startServiceAct(){
				this.$confirm({
					title:'确认开始服务了吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/startService',{
								appointment_id:this.order.appointment_id,
							}).then(res=>{
								this.$message.success('操作成功');
								this.loaddata();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			
			//完成服务
			completeAppointAct(){
				
				this.completeAppointVisible=true;
			},
			cancelCompleteAppoint(){
				this.completeAppointVisible=false;
			},
			okCompleteAppoint(){
				this.completeAppointVisible=false;
				this.loaddata();
			},
			
			//取消预约
			cancelAppointAct(){
				this.cancelAppointVisible=true;
			},
			cancelCancelAppoint(){
				this.cancelAppointVisible=false;
			},
			okCancelAppoint(){
				this.cancelAppointVisible=false;
				this.loaddata();
			},
			
		}
	}
</script>

<style>
  .member-detail-face{width: 60px;}
	.mall-goods_order-detail-tag {
		padding: 1px 8px;
		border-radius: 2px;

		font-size: 12px;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 16px;
	}

	.mall-goods_order-detail.tag {
		padding: 1px 4px;
		background: rgba(71, 114, 255, 0.1);
		border-radius: 2px;

		font-size: 12px;
		font-weight: 500;
		color: #4772FF;
	}


	.mall-goods_order-detail-info {
		background: #F9F9F9;
		border: 1px solid #EBEDF5;
		padding: 24px 0px;
	}

	.mall-goods_order-detail-subcard-item {
		width: 100%;
		height: 120px;
		background: #FFFFFF;
		border: 1px solid #EBEDF5;
		margin-bottom: 20px;
		padding: 20px;
	}

	.mall-goods_order-detail-subcard-item img {
		width: 106px;
		height: 80px;
		border-radius: 4px;
	}

	.button-deliver {
		width: 96px;
		height: 40px;
		background: #4772FF;
		border-radius: 4px;
		font-size: 16px;
		font-weight: 600;
		color: #FFFFFF;
		text-align: center;
		line-height: 40px;
		cursor: pointer;
	}
	
	.button-cancel{
		width: 96px;
		height: 40px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #000000;
		font-size: 16px;
		font-weight: 600;
		color: #000000;
		text-align: center;
		line-height: 40px;
		cursor: pointer;
	}
</style>
